import React, {useEffect,useState} from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const randomChoice = (list) => {
  return list[Math.floor(Math.random()*list.length)];
}

const IndexPage = ({data,path}) => {
let pages = {};
for(var table in data){
  console.log(table)
  pages[table] = data[table].edges.map(e => e.node.fields.path)
}
console.log (data)
let [choices,setChoices] = useState({
  death:"death",
  fraud:"fraud",
  mathmusic:"mathmusic"
});

const openLink = (e) => {
  setChoices({
    fraud: randomChoice(pages.fraud),
    mathmusic: randomChoice(pages.mathmusic)
  })
}

useEffect(() => {
  setChoices({
    fraud: randomChoice(pages.fraud),
    mathmusic: randomChoice(pages.mathmusic)
  })
  if(typeof window !== "undefined"){
  }
},[]);

return (
  <div id="index-page">
    <Seo title="Books and Tables" />
    <div id="tables">
      <a href="/death" target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover" objectPosition="50% 15%"  src="../images/landing/death table.jpg" alt="" />
      </a>
      <a href={choices.fraud} target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover" objectPosition="50% 75%"  src="../images/landing/fraud table.jpg" alt="" />
      </a>
      <a href="/greetings" target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover" objectPosition="50% 67%"  src="../images/landing/greetings table.jpg" alt="" />
      </a>
      <a href="/growth" target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover"   src="../images/landing/growth table.jpg" alt="" />
      </a>
      <a href={choices.mathmusic} target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover" objectPosition="50% 25%"  src="../images/landing/math n music table.jpg" alt="" />
      </a>
      <a href="/vangogh" target="_blank" onClick={openLink}>
        <StaticImage object-fit="cover" objectPosition="50% 90%"  src="../images/landing/van gogh table.jpg" alt="" />
      </a>
    </div>
  </div>
)}

export default IndexPage

export const query = graphql`
query GetHtml {
  fraud: allMarkdownRemark(filter: {fields: {table: {eq: "fraud"}}, frontmatter: {draft: {ne: true}}}) {
    edges {
      node {
        fields {
          path
        }
      }
    }
  }
  mathmusic: allMarkdownRemark(filter: {fields: {table: {eq: "mathmusic"}}, frontmatter: {draft: {ne: true}}}) {
    edges {
      node {
        fields {
          path
        }
      }
    }
  }
  death: allMarkdownRemark(filter: {fields: {table: {eq: "death"}}, frontmatter: {draft: {ne: true}}}) {
    edges {
      node {
        fields {
          path
        }
      }
    }
  }
}
`
